
import { Fragment, createContext, useContext, useState } from 'react';
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { authenticationProvider } from 'Common/Provider/Authentication';
import useEffectOnce from 'Common/Utility/useEffectOnce';
import { Toaster } from 'react-hot-toast';
import { useDetailMyCredential } from 'Core/Authentication/API/MyCredentialAPI';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import './index.css';
import AuthenticatedRouters from './AuthenticatedRouters';
import GuestRouters from './GuestRouters';
import { ConfirmDialog } from 'Common/UI/Block/ConfirmDialog';
import { MediaSizeDetector } from 'Common/UI/Component/DebugUI';

export const queryClient = new QueryClient()
const AuthContext = createContext()

function AuthProvider({ children }) {
  const dataLoggedUser = useDetailMyCredential({
    enabled: false,
    onError: (error) => signOut(),
    onSuccess: ({ data: response }) => {
      const data = response.data
      signIn(data.account, authenticationProvider.token())
    }
  })
  let [user, setUser] = useState(false);

  let signIn = (loggedInUser, token) => {
    return authenticationProvider.in(token, () => {
      setUser(loggedInUser)
    })
  }

  let signOut = () => {
    return authenticationProvider.out(() => {
      setUser(null)
    })
  }

  let refreshAuth = () => {
    if (authenticationProvider.authenticated()) {
      dataLoggedUser.refetch()
    } else {
      setUser(null)
    }
  }


  useEffectOnce(function init() {
    if (authenticationProvider.authenticated()) {
      dataLoggedUser.refetch()
    } else {
      setUser(null)
    }

  })
  return <AuthContext.Provider value={{ user, signIn, signOut, refreshAuth }}>{children}</AuthContext.Provider>
}

function useAuth() {
  return useContext(AuthContext)
}

function Router() {
  const auth = useAuth()

  if (auth.user === false) {
    return null
  }

  return (
    <Fragment>
      {auth.user ? <AuthenticatedRouters /> : <GuestRouters />}
    </Fragment>
  )
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <Router />
          <Toaster />

          <ConfirmDialog />
        </AuthProvider>
      </BrowserRouter>
      <MediaSizeDetector />
    </QueryClientProvider>
  );
}


export { useAuth }
export default App;
