
import jwt_decode from "jwt-decode";

const KEY_AUTHENTICATION = 'authentication-key'

const ROLE_SUPER_ADMINISTRATOR = 'SuperAdministrator'
const ROLE_EMPLOYEE = 'Employee'
const ROLE_WHISTLEBLOW_OPERATOR = 'Whistleblow-Operator'
const ROLE_FORM_OPERTAOR = 'Form-Operator'
/**
 * This represents some generic auth provider API, like Firebase.
 */
const authenticationProvider = {
    in(token, callback) {
        localStorage.setItem(KEY_AUTHENTICATION, token)
        if (callback) {
            setTimeout(callback, 100);
        }
    },
    out(callback) {
        localStorage.removeItem(KEY_AUTHENTICATION)
        if (callback) {
            setTimeout(callback, 100);
        }
    },
    authenticated() {
        return this.token() !== null
    },
    token() {
        return localStorage.getItem(KEY_AUTHENTICATION)
    },
    role() {
        const token = this.token()
        if (token === null) {
            return null
        }
        const payload = jwt_decode(token)
        return payload.data.role
    },
    isRole(role) {
        const currentRole = this.role()
        if (Array.isArray(role)) {
            for (let index in role) {
                if (currentRole === role[index]) {
                    return true
                }
            }
        } else {
            if (currentRole === role) {
                return true
            }
        }
        return false
    },
};

export { authenticationProvider, KEY_AUTHENTICATION, ROLE_SUPER_ADMINISTRATOR, ROLE_EMPLOYEE, ROLE_WHISTLEBLOW_OPERATOR, ROLE_FORM_OPERTAOR };
