import { transformResponseToList } from "Common/Utility/formatter";
import { useBrowseForm } from "Core/Form/API/FormAPI"
import { Link, useSearchParams } from "react-router-dom";
import CardListLoader from "./CardListLoader";
import EmptyState from "Common/UI/Component/EmptyState";
import Logo from "Common/UI/Component/Logo";
import { humanizeDateTime, humanizeRelativeFromDate } from "Common/Utility/humanize";


export default function BrowseFormRoute() {
    const [searchParams, setSearchParams] = useSearchParams();
    const keyword = searchParams.get('keyword')
    const browseForm = useBrowseForm({ keyword, currentPage: 1, pageSize: 20, sort: 'ID', order: 'desc', expands: [] })
    const forms = transformResponseToList(browseForm.data)

    return (
        <div>
            <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8 min-h-screen">
                <div className="absolute inset-0">
                    <div className="bg-white h-1/3 sm:h-2/3" />
                </div>
                <div className="relative max-w-7xl mx-auto">
                    <div className="text-center">
                        <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">Formulir</h2>
                        <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                            Berikut semua formulir yang sedang tayang dan/atau aktif.
                        </p>
                    </div>
                    
                    {browseForm.isLoading || forms.length !== 0 ? null : <EmptyState.CardList />}
                    <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                        {!browseForm.isLoading ? null : <CardListLoader />}
                        {forms.map((form) => (
                            <Link to={`/fill/${form.ID}`}  key={form.ID} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                                <div className="flex-shrink-0">
                                    {form.banner ?
                                        <img className="h-48 w-full object-cover" src={form.bannerURL} alt="" />:
                                        <div className="h-48 w-full object-cover bg-gray-300"></div>
                                    }
                                </div>
                                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                                    <div className="flex-1">
                                        <Link to={`/fill/${form.ID}`} className="block mt-2">
                                            <p className="text-xl font-semibold text-gray-900">{form.name}</p>
                                        </Link>
                                    </div>
                                    <div className="mt-6 flex items-center">
                                        <div className="flex-shrink-0">
                                            <span>
                                                <span className="sr-only">Admin Formulir Mitras DUDI</span>
                                                <Logo.CompanySmallLogo className="h-10 w-10 rounded-full" />
                                            </span>
                                        </div>
                                        <div className="ml-3">
                                            <p className="text-sm font-medium text-gray-900">
                                                <span className="hover:underline">
                                                    Admin Formulir Mitras DUDI
                                                </span>
                                            </p>
                                            <div className="flex space-x-1 text-sm text-gray-500">
                                                <time dateTime={form.created}>{humanizeDateTime(form.created)}</time>
                                                <span aria-hidden="true">&middot;</span>
                                                <span>{humanizeRelativeFromDate(form.created)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>


    )
}