import Icon from "Common/UI/Component/Icon";

import { Popover } from '@headlessui/react'
import classNames from "classnames";
import Logo from "Common/UI/Component/Logo";
import { Link, useSearchParams } from "react-router-dom";

const user = {
    name: 'Chelsea Hagon',
    email: 'chelsea.hagon@example.com',
    imageUrl:
        'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}
const navigation = [
    { name: 'Dashboard', href: '#', current: true },
    { name: 'Calendar', href: '#', current: false },
    { name: 'Teams', href: '#', current: false },
    { name: 'Directory', href: '#', current: false },
]
const userNavigation = [
    { name: 'Your Profile', href: '#' },
    { name: 'Settings', href: '#' },
    { name: 'Sign out', href: '#' },
]


export default function MainLayout({ children, className = null }) {
    const [searchParams] = useSearchParams();
    const keyword = searchParams.get('keyword')

    return (
        <div>
            {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
            <div>
                <Popover
                    as="header"
                    className={({ open }) =>
                        classNames(
                            open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
                            'bg-white shadow-sm lg:static lg:overflow-y-visible'
                        )
                    }
                >
                    {({ open }) => (
                        <>
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8">
                                    <div className="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2">
                                        <div className="flex-shrink-0 flex items-center">
                                            <Link to="/">
                                                <Logo.CompanyLogo
                                                    className="block h-8 w-auto"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6">
                                        <form method="GET">
                                            <div className="flex items-center px-6 py-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
                                                <div className="w-full">
                                                    <label htmlFor="search" className="sr-only">
                                                        Search
                                                    </label>
                                                    <div className="relative">
                                                        <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                                                            <Icon.IconSolid.MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        </div>
                                                        <input
                                                            name="keyword"
                                                            className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                            placeholder="Search"
                                                            type="search"
                                                            defaultValue={keyword}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
                                <div className="max-w-3xl mx-auto px-2 pt-2 pb-3 space-y-1 sm:px-4">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            aria-current={item.current ? 'page' : undefined}
                                            className={classNames(
                                                item.current ? 'bg-gray-100 text-gray-900' : 'hover:bg-gray-50',
                                                'block rounded-md py-2 px-3 text-base font-medium'
                                            )}
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                <div className="border-t border-gray-200 pt-4 pb-3">
                                    <div className="max-w-3xl mx-auto px-4 flex items-center sm:px-6">
                                        <div className="flex-shrink-0">
                                            <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                                        </div>
                                        <div className="ml-3">
                                            <div className="text-base font-medium text-gray-800">{user.name}</div>
                                            <div className="text-sm font-medium text-gray-500">{user.email}</div>
                                        </div>
                                        <button
                                            type="button"
                                            className="ml-auto flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            <span className="sr-only">View notifications</span>
                                            <Icon.IconSolid.BellIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div className="mt-3 max-w-3xl mx-auto px-2 space-y-1 sm:px-4">
                                        {userNavigation.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                                            >
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </Popover.Panel>
                        </>
                    )}
                </Popover>

            </div>

            <div className="bg-gray-50 h-full">
                <main>
                    {children}
                </main>
            </div>
        </div>

    )
}