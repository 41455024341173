import { transformResponseToSingle } from "Common/Utility/formatter"
import { humanizeDateTime } from "Common/Utility/humanize"
import { useDetailForm } from "Core/Form/API/FormAPI"
import { Skeleton } from "primereact/skeleton"
import { useNavigate, useParams } from "react-router-dom"
import { Fragment } from "react"


export default function DetailFormRoute() {
    const { id } = useParams()
    const navigate = useNavigate()
    const detailForm = useDetailForm(id, {
        onSuccess: (response) => {
            const fetched = transformResponseToSingle(response)
            console.log('fetched', fetched)
            loadScript(fetched.content)
        },
        onError: (response) => navigate('/not-found')
    })
    const form = transformResponseToSingle(detailForm.data) || {}

    const loadScript = (src) => {
        const tag = document.createElement('script')
        tag.async = false
        tag.src = src
        const body = document.getElementsByTagName('body')[0]
        body.appendChild(tag)
    }

    return (
        <div className="relative bg-white">

            <div className="relative py-16 bg-white overflow-hidden ">
                <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full ">
                    <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                        <svg
                            className="absolute top-12 left-full transform translate-x-32"
                            width={404}
                            height={384}
                            fill="none"
                            viewBox="0 0 404 384"
                        >
                            <defs>
                                <pattern
                                    id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                        </svg>
                        <svg
                            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                            width={404}
                            height={384}
                            fill="none"
                            viewBox="0 0 404 384"
                        >
                            <defs>
                                <pattern
                                    id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                        </svg>
                        <svg
                            className="absolute bottom-12 left-full transform translate-x-32"
                            width={404}
                            height={384}
                            fill="none"
                            viewBox="0 0 404 384"
                        >
                            <defs>
                                <pattern
                                    id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                        </svg>
                    </div>
                </div>
                <div className="flex justify-center">
                    <div className="relative px-4 sm:px-6 lg:px-8 max-w-7xl">
                        <div className="text-lg max-w-prose mx-auto mb-10">
                            <h1>
                                <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
                                    {detailForm.isLoading ?
                                        <Skeleton className="mb-2 h-5 w-full"></Skeleton> :
                                        humanizeDateTime(form.created)
                                    }
                                </span>
                                <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                    {detailForm.isLoading ?
                                        <Skeleton className="mb-2 h-5 w-full"></Skeleton> :
                                        form.name
                                    }
                                </span>
                            </h1>
                        </div>
                        {!form.banner ? null :
                            <figure>

                                <Fragment>
                                    {!detailForm.isLoading ? null :
                                        <Skeleton className="mb-2 h-56 w-full"></Skeleton>
                                    }
                                    <img
                                        className="w-full object-cover rounded-lg"
                                        src={form.bannerURL}
                                        alt=""
                                        width={1310}
                                        height={873}
                                    />

                                </Fragment>
                                <figcaption>Banner</figcaption>
                            </figure>
                        }

                        <div className="ff-compose-container w-full" id="ff-compose"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}