import moment from "moment";

export function humanizeDate(dateString) {
    return moment(dateString, 'YYYY-MM-DD HH:mm:ss').format('D MMM, YYYY')
}
export function humanizeTime(dateString) {
    return moment(dateString, 'HH:mm:ss').format('H:mm ')
}
export function humanizeRelativeFromDate(dateString) {
    return moment(dateString, 'YYYY-MM-DD HH:mm:ss').fromNow()
}
export function humanizeRelativeToDate(dateString) {
    return moment(dateString, 'YYYY-MM-DD HH:mm:ss').toNow()
}
export function humanizeDateTime(dateString) {
    return moment(dateString, 'YYYY-MM-DD HH:mm:ss').format('H:mm @ D MMM, YYYY')
}
export function humanizeCurrency(value) {
    return 'Rp. ' + humanizeNumber(value)
}
export function humanizeNumber(value, maximumFractionDigits = 2, minimumFractionDigits = 2) {
    return parseFloat(value).toLocaleString('id-ID', { maximumFractionDigits, minimumFractionDigits })
}
export function humanizePercentage(value, precision = 0) {
    return (value * 100.0).toFixed(precision) + '%'
}
export function humanizeDuration(start, end) {
    return moment.duration(moment(end, 'HH:mm:ss').diff(moment(start, 'HH:mm:ss')))
}