import { requestGet, requestPost } from "Common/Provider/RestClient"
import { useMutation, useQuery } from "@tanstack/react-query"

export function useBrowseForm({ onSuccess, deleted, start, keyword, pageSize, currentPage, sort = 'datetime', order = 'asc', expands = [] }) {
    const path = `/form/form/form`
    const data = useQuery({
        queryKey: [path, pageSize, currentPage, keyword, deleted, start, sort, order, expands],
        queryFn: () => requestGet(path, { keyword, pageSize, currentPage, deleted, start, sort, order, expand: expands.join(',') }),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}

export function useCreateForm({ onSuccess, onError }) {
    const mutation = useMutation({
        mutationFn: ({ name, banner, startDate, endDate, content, deleted }) => {
            const path = `/form/form/form`;
            return requestPost(path, { name, banner, startDate, endDate, content, deleted })
        },
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}


export function useDetailForm(ID, { enabled = true, expands = [], onSuccess, onError }) {
    const path = `/form/form/form/${ID}`
    const data = useQuery({
        queryKey: [path, expands],
        queryFn: () => requestGet(path, { expand: (expands || []).join(',') }, {}, onError),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        enabled
    })
    return data
}


export function useUpdateForm({ onSuccess, onError }) {
    const mutation = useMutation({
        mutationFn: ({ ID, name, banner, startDate, endDate, content, deleted }) => {
            const path = `/form/form/form/${ID}`;
            return requestPost(path, { name, banner, startDate, endDate, content, deleted })
        },
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}
