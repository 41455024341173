import axios from "axios"
import { stringify } from 'query-string'

export function requestPost(path, payload, headers = {}) {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_BASE_URL_API}${path}`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
                ...headers
            }
        })
            .then(response => resolve(response))
            .catch(error => reject(error))
    })
}

export function requestGet(path, parameter, headers = {}, onError = null) {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_BASE_URL_API}${path}?${stringify(parameter)}`, {
            headers: {
                ...headers
            }
        })
            .then(response => resolve(response))
            .catch(error => onError(error))
    })
}

export function requestDelete(path, parameter, headers = {}) {
    return new Promise((resolve, reject) => {
        axios.delete(`${process.env.REACT_APP_BASE_URL_API}${path}?${stringify(parameter)}`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                ...headers
            }
        })
            .then(response => resolve(response))
            .catch(error => reject(error))
    })
}
