import MainLayout from "Common/UI/Layout/Main/MainLayout";
import PageRoutes from "Core/Content/UseCase/Route/Page/PageRoutes";
import FormRoutes from "Core/Form/UseCase/Route/Form/FormRoutes";
import { Route, Routes } from "react-router-dom";

export default function AuthenticatedRouters() {
    return (
        <MainLayout>
            <Routes>
                <Route path="/" element={<FormRoutes.BrowseFormRoute />} />
                <Route path='fill/:id' element={<FormRoutes.DetailFormRoute />} />

                <Route path="*" element={<PageRoutes.NotFoundRoute />} />
            </Routes>
        </MainLayout>
    )
}